import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kaufanbot = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was ist ein Kaufanbot und wann sollte ich es machen?" showCalc={false} />
            <Article>
                <p>
                    Du hast eine Wohnung gefunden, die dir gefällt, bist dir aber noch nicht sicher, ob du sie nehmen
                    sollst? Dann gib noch kein Kaufanbot ab! Kaufanbote bedeuten nämlich eine verpflichtende
                    Vertragserklärung. Die macht man erst, wenn man sich sicher ist, dass man die Immobilie wirklich
                    kaufen möchte. Und selbst dabei gibt es einiges zu beachten. Worauf genau, erklären wir dir in
                    diesem Beitrag.
                </p>
                <p>
                    Check auch den miracl{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kredit-Rechner
                    </Link>{" "}
                    an und hol dir eine erste Einschätzung zu Zinsen, Raten und mehr.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was bedeutet ein Kaufanbot genau?</h2>
                <p>
                    Wenn du am Kauf einer Immobilie interessiert bist, kannst du dem Verkäufer ein Kaufanbot zustellen.
                    Dadurch bestätigst du dem Eigentümer oder der Eigentümerin verbindlich, dass du die Immobilie kaufen
                    möchtest. Du nennst einen bestimmten Preis und Konditionen, unter denen der Kauf für dich in Frage
                    kommt und machst damit dem Verkäufer oder der Verkäuferin ein Angebot. Diese*r hat dann eine
                    bestimmte Frist, um auf dein Kaufanbot zu reagieren.
                </p>
                <p>
                    Ein Kaufanbot machst du also erst zum Zeitpunkt, an dem die{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    bereits geklärt ist und du dir über den Immobilienkauf sicher bist. Es handelt sich dabei
                    tatsächlich um eine rechtlich bindende Vertragserklärung. Sobald du das Kaufanbot abgibst, bist du
                    sofort daran gebunden, die Immobilie zu den festgelegten Konditionen zu kaufen. Auf die Dauer dieser
                    Bindung einigte man sich entweder gemeinsam, oder aber sie gilt für bis zu ca. 14 Tage. Sobald dein
                    Kaufanbot angenommen wird, ist der Kaufvertrag zustande gekommen und die Maklerprovision wird
                    fällig. Das heißt, dass du während der Bindungsdauer unbedingt eine Antwort abwarten musst. Du
                    solltest dich niemals mit mehreren Kaufanboten an mehrere Objekte binden, weil das viel zu riskant
                    wäre.
                </p>
                <p>
                    In vielen Maklerbüros liegen bereits Formulare für das Kaufanbot. Die darin enthaltenen
                    Informationen sind aber nicht ausreichend, um deine Kaufentscheidung zu beeinflussen. Diese
                    Entscheidung sollte schon viel vorher gut überlegt getroffen worden sein. Das Kaufanbot sollte
                    lediglich wichtige Punkte für den{" "}
                    <Link to="/artikel/kaufvertrag/" target="_blank" rel="noreferrer noopener">
                        Kaufvertrag
                    </Link>{" "}
                    enthalten. Lass dich also niemals zu einer Unterschrift drängen, auch wenn du fürchtest, dass die
                    Wohnung sonst nicht mehr da ist.
                </p>
                <hr />

                <h2>Kaufanbote am besten selbst formulieren</h2>
                <p>
                    Wenn du ein Kaufanbot machen möchtest, solltest du es am besten selbst mit der Hilfe deines Anwalts
                    oder deiner Anwältin schreiben. Dein Immobilienmakler oder deine Maklerin müssen dir rechtzeitig
                    alle Informationen schriftlich geben, die du zur Beurteilung der Immobilie und für deine
                    Entscheidung benötigst. Makler*innen unterstehen dieser Informationspflicht und sollten sie von sich
                    aus erfüllen, wenn sie seriös arbeiten.
                </p>
                <hr />

                <h2>Was ist noch zu beachten?</h2>
                <p>
                    Bevor du ein Kaufanbot machst, muss alles ganz sicher sein. Du musst über deine Finanzierung sicher
                    sein, darüber, dass du die{" "}
                    <Link to="/artikel/immobilienbewertung/" target="_blank" rel="noreferrer noopener">
                        Immobilie wirklich willst
                    </Link>{" "}
                    und bereit bist, den Kauf einzugehen. Davor musst du dich über die FInanzierung und alles rund um
                    den Immobilienkauf gut an verschiedenen Stellen informieren. Wir haben ein Team von Finanzexperten,
                    die dafür ausgebildet sind. Trau dich, dir Ratschläge einzuholen, bevor du voreilige Entscheidungen
                    triffst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Hier geht es zum kostenlosen Erstgespräch!
                    </a>
                </p>
                <hr />

                <h2>Ist ein Rücktritt von einem Kaufanbot möglich?</h2>
                <p>
                    Hast du schon ein Kaufanbot gestellt, von dem du jetzt wieder zurücktreten möchtest? Das ist in
                    bestimmten Fällen möglich. Beispielsweise, wenn du die Immobilie am Tag der Unterschreibung des
                    Kaufanbots erst zum ersten Mal besichtigt hast, wenn das Objekt dazu bestimmt war, dass du selbst
                    darin wohnst, es also dein Hauptwohnsitz oder der eines Angehörigen werden sollte. Für den Rücktritt
                    von einem Kaufanbot gibt es aber eine zeitliche Frist, die eingehalten werden muss. Die beginnt,
                    sobald du eine Kopie des Vertrags oder Anbot erhältst und schriftlich über das Rücktrittsrecht
                    informiert wurdest. Von diesem Zeitpunkt an hast du maximal eine Woche Zeit, um dich anders zu
                    entscheiden und von deinem Anbot wieder zurückzutreten. Wurdest du nicht über dein Rücktrittsrecht
                    informiert und hast keine Kopie erhalten, hast du länger Zeit, nämlich genau einen Monat ab dem Tag
                    deiner ersten{" "}
                    <Link to="/artikel/besichtigunstermin/" target="_blank" rel="noreferrer noopener">
                        Besichtigung
                    </Link>
                    .
                </p>
                <p>
                    Wenn du von deinem Kaufanbot zurücktreten möchtest, solltest du das ebenfalls schriftlich machen, am
                    besten mit einem eingeschriebenen Brief. Aus Beweisgründen wäre es hier wichtig, dass du die
                    Rechnung von der Post sicher aufhebst.
                </p>
                <p>
                    Leider achten viele Maklerbüros darauf, dass das Kaufanbot schon mehrere Tage nach dem
                    Besichtigungstermin unterschreibst und nicht schon am selben Tag. Sei also sehr vorsichtig, bevor du
                    irgendetwas unterschreibst.
                </p>
                <p>
                    Achtung: Wenn du eine Wohnung nur „reservieren“ willst, mach niemals ein Kaufanbot! In diesem Fall
                    kannst du nichts tun, als mit dem Makler oder der Maklerin zu sprechen und zu hoffen, dass dir die
                    Wohnung nicht weggeschnappt wird.
                </p>
            </Article>

            <BreadcrumbList page={"kaufanbot"}></BreadcrumbList>
            <ArticleStructuredData
                page={"kaufanbot"}
                heading={"Was ist ein Kaufanbot und wann sollte ich es machen?"}
            />
        </Layout>
    );
};

export default Kaufanbot;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kaufanbot", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
